import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { getCards, createCard, deleteCard } from "../../services/user/user"
import CardForm from "./CardForm"
import Loader from "../Core/Loader"
import {
  AccountButtonDark,
  AccountButtonLight,
  FormPopup,
  FormPopupOverlay,
  PopupCloseBtn,
  PopupFormContainer,
  PopupHeaderRow,
  PopupTitle,
  Center,
  LeftPadContainer,
  Info,
  H1,
} from "./AccountStyles"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import {
  Amex,
  Diners,
  Discover,
  UnionPay,
  Generic,
  Jcb,
  Mastercard,
  Visa,
} from "react-pay-icons"
import colors from "../../styles/colors"
import { ReactComponent as CloseIcon } from "../../resources/img/svg/close.svg"
import { useUserContext } from "../../context/UserContext"
import { updateUserData } from "../../services/swell/user"
import OnClickButton from "src/components/Core/OnClickButton"

const LoaderWrapper = styled.div`
  width: 44px;
  height: 44px;
`

const Error = styled(Info)`
  color: #e61c1c;
`

const NewPaymentBtn = styled.div`
  margin-top: 2.5rem;
  width: 100%;
  @media (min-width: ${breakpoints.md}) {
    width: auto;
  }
`

const Cards = styled.div`
  display: grid;
  gap: 2rem;
  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-rows: minmax(0, 1fr);
  }
`

const PaymentCard = styled.div`
  box-shadow: 0 0 #0000, 0 0 #0000, 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  padding: 1rem 0;
  background: #fffefc;
  border-radius: 0.25rem;
  ${fonts.openSansRegular};
  font-size: 1rem;
  line-height: 1.5;
  color: #1c1a17;
  width: 100%;
`
const CardBlock = styled.div`
  padding: 0 1rem;
`
const CardBlockBottom = styled.div`
  margin-top: 1rem;
  padding: 1rem 1rem 0;
  border-top: 1px solid #d9d5d0;
`

const CardBlockTitle = styled.h3`
  margin-bottom: 0.5rem;
  font-size: 0.889rem;
  line-height: 1.5;
  ${fonts.openSansMedium};
  color: #1c1a17;
`

const ImageRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 1.5rem;
`

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${(props) => (props.bottom ? "0 1rem" : "0 0 1rem")};
`

const DetailCopy = styled.span`
  ${fonts.openSansRegular};
  font-size: 0.79rem;
  line-height: 1.5;
  color: #1c1a17;
  display: ${(props) => (props.inline ? "inline-block" : "block")};
`

const DetailCopyBold = styled(DetailCopy)`
  font-weight: 600;
  margin-bottom: ${(props) => (props.margin ? "0.5rem" : "0")};
`

const DetailCopyLarge = styled(DetailCopy)`
  font-size: 1rem;
`
const DotsContainer = styled.div`
  display: flex;
  white-space: pre-wrap;
`

const Dots = styled.span`
  letter-spacing: 0.25rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #1c1a17;
  ${fonts.openSansRegular};
`

const DeleteBtnWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const DeleteBtn = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  padding: 0 0.5rem;
  cursor: pointer;
  span {
    color: #090602;
  }
  &:hover {
    span {
      color: ${colors.royalBlue};
    }
  }
`

const IconWrapper = styled.div`
  color: inherit;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`
const PopupButtons = styled.div`
  @media (min-width: ${breakpoints.md}) {
    display: flex;
  }
`

const Information = styled.p`
  margin: 0;
  margin-bottom: 2.5rem;
  ${fonts.openSansRegular};
  font-size: 1rem;
  line-height: 1.5;
  color: #1c1a17;
`

const PopupButtonWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${breakpoints.sm};
  @media (min-width: ${breakpoints.md}) {
    width: 50%;
    order: ${(props) => (props.first ? "1" : "2")};
    margin-left: ${(props) => (props.first ? "0" : "1rem")};
    max-width: unset;
  }
`
const Default = styled.div`
  padding: 0.5rem;
  background: #f2efeb;
  ${fonts.openSansMedium};
  border-radius: 0.25rem;
  font-size: 0.79rem;
  line-height: 1.25;
  text-transform: uppercase;
  color: #1c1a17;
`

const DefaultBtn = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1);
  font-size: 0.889rem;
  line-height: 1.5;
  ${fonts.openSansMedium};
  color: #090602;
  &:hover {
    color: ${colors.royalBlue};
  }
`

const Payment = () => {
  const [cards, setCards] = useState([])
  const [openNewPopup, setOpenNewPopup] = useState(false)
  const [removePopupId, setRemovePopupId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [idToBeDefault, setIdToBeDefault] = useState(null)
  const [settingDefault, setSettingDefault] = useState(false)

  const { user, setUser, userPaymentMethods, setUserPaymentMethods } =
    useUserContext()

  let overlayRef = useRef(null)
  let overlayDefRef = useRef(null)

  useEffect(() => {
    if (userPaymentMethods !== null) {
      setCards(userPaymentMethods)
      setIsLoading(false)
    } else {
      getCards()
        .then((res) => {
          setCards(res.results)
          setUserPaymentMethods(res.results)
          setIsLoading(false)
        })
        .catch((err) => {
          console.err(err)
          setIsError(true)
        })
    }
  }, [])

  const handleDeleteCard = async (id) => {
    setRemoving(true)
    let updatedCards
    try {
      const response = await deleteCard(id)
      updatedCards = cards.filter((card) => card.id !== id)
      setCards(updatedCards)
      setUserPaymentMethods(updatedCards)
      setRemovePopupId(null)
      setRemoving(false)
    } catch (error) {
      console.error(error)
      setRemovePopupId(null)
      setRemoving(false)
    }
  }

  const getCardImage = (brand) => {
    const styleObj = { width: 50 }
    switch (brand) {
      case "American Express":
        return <Amex style={styleObj} />
      case "Diners Club":
        return <Diners style={styleObj} />
      case "Discover":
        return <Discover style={styleObj} />
      case "JCB":
        return <Jcb style={styleObj} />
      case "MasterCard":
        return <Mastercard style={styleObj} />
      case "UnionPay":
        return <UnionPay style={styleObj} />
      case "Visa":
        return <Visa style={styleObj} />
      default:
        return <Generic style={styleObj} />
    }
  }

  const closeRemovePopupOnOverlay = (e) => {
    if (overlayRef.current && overlayRef.current === e.target) {
      setRemovePopupId(null)
    }
  }
  const closeDefaultPopupOnOverlay = (e) => {
    if (overlayDefRef.current && overlayDefRef.current === e.target) {
      setIdToBeDefault(null)
    }
  }

  let defaultCardId

  if (user?.billing?.account_card_id) {
    defaultCardId = user.billing.account_card_id
  }

  const handleDefaultChange = async (id) => {
    setSettingDefault(true)
    try {
      const updatedUser = await updateUserData({
        billing: {
          account_card_id: id,
        },
      })
      setUser(updatedUser)
      setSettingDefault(false)
      setIdToBeDefault(null)
    } catch (err) {
      console.error(err)
    }
  }

  const getContent = () => {
    if (isError) {
      return <Error>An error occured</Error>
    }

    if (isLoading) {
      return (
        <Center>
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        </Center>
      )
    }

    return (
      <>
        {cards.length > 0 ? (
          <Cards>
            {cards.map((card, index) => {
              const { id, brand, last4, exp_month, exp_year, billing } = card
              const shortExpYear = exp_year.toString().slice(-2)
              return (
                <PaymentCard key={card.id}>
                  <CardBlock>
                    <ImageRow>
                      <div aria-label={brand}>{getCardImage(brand)}</div>
                      <div>
                        {defaultCardId === id ? (
                          <Default>Default</Default>
                        ) : (
                          <DefaultBtn onClick={() => setIdToBeDefault(id)}>
                            Use as default
                          </DefaultBtn>
                        )}
                      </div>
                    </ImageRow>

                    <CardRow>
                      <DotsContainer>
                        <Dots>···· ···· </Dots>
                        <DetailCopyLarge>{last4}</DetailCopyLarge>
                      </DotsContainer>
                      <DetailCopyLarge>
                        {exp_month}/{shortExpYear}
                      </DetailCopyLarge>
                    </CardRow>
                    <CardRow>
                      {billing?.name ? (
                        <DetailCopyLarge>{billing.name}</DetailCopyLarge>
                      ) : user?.billing?.name ? (
                        <DetailCopyLarge>{user.billing.name}</DetailCopyLarge>
                      ) : (
                        <div></div>
                      )}
                      <DeleteBtnWrapper>
                        <DeleteBtn onClick={() => setRemovePopupId(card.id)}>
                          <DetailCopyBold inline>Delete</DetailCopyBold>
                        </DeleteBtn>
                      </DeleteBtnWrapper>
                    </CardRow>
                  </CardBlock>
                  <CardBlockBottom>
                    <CardBlockTitle>Billing Address</CardBlockTitle>
                    {billing ? (
                      <>
                        <DetailCopy>{billing.name}</DetailCopy>
                        <DetailCopy>{billing.address1}</DetailCopy>
                        {billing.address2 && (
                          <DetailCopy>{billing.address2}</DetailCopy>
                        )}
                        <DetailCopy>
                          {billing.zip
                            ? `${billing.city}, ${billing.zip}`
                            : billing.city}
                        </DetailCopy>
                        <DetailCopy>
                          {billing.state
                            ? `${billing.state}, ${billing.country}`
                            : billing.country}
                        </DetailCopy>
                      </>
                    ) : (
                      <DetailCopy>No billing address assigned yet.</DetailCopy>
                    )}
                  </CardBlockBottom>
                </PaymentCard>
              )
            })}
          </Cards>
        ) : (
          <Info>
            There are no payment methods associated with this account.
          </Info>
        )}
        <NewPaymentBtn>
          <OnClickButton blue onClick={() => setOpenNewPopup(true)}>
            Add new payment method
          </OnClickButton>
        </NewPaymentBtn>

        <CardForm
          isOpen={openNewPopup}
          close={() => {
            setOpenNewPopup(false)
          }}
          cards={cards}
          setCards={setCards}
        />
        <FormPopupOverlay
          ref={overlayRef}
          open={removePopupId !== null}
          onClick={closeRemovePopupOnOverlay}
        >
          <FormPopup noScroll>
            <PopupFormContainer>
              <PopupHeaderRow>
                <PopupTitle>Delete payment method</PopupTitle>
                <PopupCloseBtn onClick={() => setRemovePopupId(null)}>
                  <IconWrapper>
                    <CloseIcon />
                  </IconWrapper>
                </PopupCloseBtn>
              </PopupHeaderRow>
              <Information>
                Are you sure you want to remove this payment method?
              </Information>
              <PopupButtons>
                <PopupButtonWrapper>
                  <AccountButtonDark
                    onClick={() => handleDeleteCard(removePopupId)}
                  >
                    {removing ? "Removing" : "Yes, remove it."}
                  </AccountButtonDark>
                </PopupButtonWrapper>
                <PopupButtonWrapper first>
                  <AccountButtonLight onClick={() => setRemovePopupId(null)}>
                    No, keep it.
                  </AccountButtonLight>
                </PopupButtonWrapper>
              </PopupButtons>
            </PopupFormContainer>
          </FormPopup>
        </FormPopupOverlay>

        <FormPopupOverlay
          ref={overlayDefRef}
          open={idToBeDefault !== null}
          onClick={closeDefaultPopupOnOverlay}
        >
          <FormPopup noScroll>
            <PopupFormContainer>
              <PopupHeaderRow>
                <PopupTitle>Set default payment method</PopupTitle>
                <PopupCloseBtn onClick={() => setIdToBeDefault(null)}>
                  <IconWrapper>
                    <CloseIcon />
                  </IconWrapper>
                </PopupCloseBtn>
              </PopupHeaderRow>
              <Information>
                Are you sure you want to make this your default payment method?
              </Information>
              <PopupButtons>
                <PopupButtonWrapper>
                  <AccountButtonDark
                    onClick={() => handleDefaultChange(idToBeDefault)}
                  >
                    {settingDefault ? "Setting" : "Yes"}
                  </AccountButtonDark>
                </PopupButtonWrapper>
                <PopupButtonWrapper first>
                  <AccountButtonLight onClick={() => setIdToBeDefault(null)}>
                    No
                  </AccountButtonLight>
                </PopupButtonWrapper>
              </PopupButtons>
            </PopupFormContainer>
          </FormPopup>
        </FormPopupOverlay>
      </>
    )
  }

  return (
    <div>
      <LeftPadContainer>
        <H1>Payments</H1>
      </LeftPadContainer>
      <LeftPadContainer>{getContent()}</LeftPadContainer>
    </div>
  )
}

export default Payment
