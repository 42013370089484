import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import {
  AccountButtonDark,
  PopupCloseBtn,
  PopupFormContainer,
  PopupHeaderRow,
  PopupTitle,
  FormPopup,
  FormPopupOverlay,
  Info,
} from "./AccountStyles"
import { ReactComponent as CloseIcon } from "../../resources/img/svg/close.svg"
import { luhnCheck } from "../../services/account/popupFormHelper"
import AccountInputField from "./AccountInputField"
import {
  createCard,
  updateUserData,
  updateCardBillingAddress,
} from "../../services/user/user"
import ExpirationInput from "./ExpirationInput"
import { useUserContext } from "../../context/UserContext"
import AccountCheckbox from "./AccountCheckbox"
import { getAddresses } from "../../services/swell/user"
import Select from "react-select"
import { selectStyles } from "src/styles/select"
import colors from "../../styles/colors"
import Arrow from "../../resources/img/svg/arrow-down-light.svg"
import AddressForm from "./AddressForm"
import OnClickButton from "src/components/Core/OnClickButton"

const IconWrapper = styled.div`
  color: inherit;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const FormContent = styled.div`
  padding: 1.5rem 0 1rem;
`

const ButtonWrapper = styled.div`
  padding: 1rem 0;
  margin: 0 auto;
  width: 100%;
  max-width: ${breakpoints.sm};
  button {
    width: 100%;
  }
  @media (min-width: ${breakpoints.md}) {
    max-width: unset;
  }
`
const Error = styled.p`
  color: #f0523d;
  font-size: 10px;
  line-height: 22px;
  ${fonts.openSansRegular};
`

const DoubleFields = styled.div`
  display: flex;
`
const FieldWrapper = styled.div`
  width: 50%;
  &:first-of-type {
    margin-right: 0.75rem;
  }
  &:last-of-type {
    margin-left: 0.75rem;
  }
`

const FormBlock = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  border-top: 1px solid #d9d5d0;
`

const FormSubTitle = styled.h3`
  ${fonts.openSansMedium};
  font-size: 1rem;
  line-height: 1.5;
  color: #1c1a17;
  margin-bottom: 0.5rem;
`

const SelectWrapper = styled.div`
  margin-bottom: 1.5rem;
`

const NewAddressBtn = styled.div`
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  ${fonts.openSansMedium};
  font-size: 0.889rem;
  line-height: 1.5;
  color: #1c1a17;
  margin-bottom: 0.5rem;
`

const initialFieldsObj = {
  number: "",
  expMonth: "",
  expYear: "",
  cvc: "",
}

const styledOptionStyles = () => {
  return {
    ...selectStyles(),
    control: (styles) => ({
      ...styles,
      borderRadius: ".25rem",
      border: `1px solid #D9D5D0`,
      padding: "0.5rem",
      boxShadow: "none",
      cursor: "pointer",
      minHeight: "unset",
      fontFamily: "'Open Sans',sans-serif",
      fontWeight: "500",
      lineHeight: "1.5",
      background: "white",
      color: "#090602",
      ":hover": {
        borderColor: colors.midGrey,
      },
    }),
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
      color: "#1c1a17",
      fontSize: "0.889rem",
      fontWeight: "400",
      lineHeight: "1.5",
      opacity: state.isDisabled ? 0.3 : 1,
      background: state.isSelected
        ? "rgba(49,154,213, 0.4)"
        : state.isFocused
        ? colors.white
        : colors.white,
      ":hover": {
        background: state.isSelected
          ? "rgba(49,154,213, 0.4)"
          : state.isDisabled
          ? "transparent"
          : "rgba(49,154,213, 0.2)",
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.889rem",
      lineHeight: "1.5",
      fontFamily: "'Open Sans',sans-serif",
      fontWeight: "500",
      color: "#090602",
      padding: "0",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.889rem",
      lineHeight: "1.5",
      fontFamily: "'Open Sans',sans-serif",
      fontWeight: "500",
      color: "#090602",
      padding: "0",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      background: "url(" + Arrow + ") no-repeat",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "0.1s",
      backgroundSize: "10px 10px",
      backgroundPosition: "center",
      width: "30px",
      svg: {
        display: "none",
      },
    }),
  }
}

const CardForm = ({ close, isOpen, cards, setCards }) => {
  const [reset, setReset] = useState(false)
  const [formError, setFormError] = useState(null)
  const [formData, setFormData] = useState({ ...initialFieldsObj })
  const [submitting, setSubmitting] = useState(false)
  const { setUserPaymentMethods } = useUserContext()
  const [makeDefaultCard, setMakeDefaultCard] = useState(false)
  const [addresses, setAddresses] = useState([])
  const { userAddresses, setUserAddresses } = useUserContext()
  const [selectedAddressId, setSelectedAddressId] = useState("")
  const [openAddressForm, setOpenAddressForm] = useState(false)

  const [fieldErrors, setFieldErrors] = useState({
    ...initialFieldsObj,
  })

  useEffect(() => {
    if (userAddresses !== null) {
      setAddresses(userAddresses)
    } else {
      getAddresses()
        .then((res) => {
          setAddresses(res.results)
          setUserAddresses(res.results)
        })
        .catch((e) => {
          console.error(e)
        })
    }
    handleFormDataState()
    setReset(true)
  }, [])

  useEffect(() => {
    if (cards && cards.length > 0) {
      setMakeDefaultCard(false)
    } else {
      setMakeDefaultCard(true)
    }
  }, [cards])

  let overlayRef = useRef(null)

  const handleFormDataState = () => {
    setFormData({ ...initialFieldsObj })
    if (cards && cards.length > 0) {
      setMakeDefaultCard(false)
    } else {
      setMakeDefaultCard(true)
    }
  }

  const handleChange = (name, value) => {
    let updatedFormData = formData
    if (typeof updatedFormData[name] !== "undefined") {
      updatedFormData[name] = value
    } else {
      updatedFormData.billing[name] = value
    }
    setFormData(updatedFormData)
  }

  const resetForm = () => {
    setFieldErrors({ ...initialFieldsObj })
    setFormError(null)
    handleFormDataState()
    if (cards && cards.length > 0) {
      setMakeDefaultCard(false)
    } else {
      setMakeDefaultCard(true)
    }
    setReset(true)
  }

  const closePopup = () => {
    resetForm()
    close()
  }

  const closeOnOverlay = (e) => {
    if (overlayRef.current && overlayRef.current === e.target) {
      closePopup()
    }
  }

  const handleCheckboxChange = () => {
    if (cards && cards.length > 0) {
      setMakeDefaultCard(!makeDefaultCard)
    }
  }

  const validateForm = () => {
    const formFields = Object.keys(formData)
    let hasError = false
    const updatedFieldErrors = { ...fieldErrors }

    formFields.forEach((formField) => {
      if (formData[formField].trim() === "") {
        let fieldName
        if (formField === "number") {
          fieldName = "card number"
        }
        if (formField === "expMonth") {
          fieldName = "card expiry date"
        }
        if (formField === "cvc") {
          fieldName = "card security code"
        }
        updatedFieldErrors[formField] = `Please enter your ${fieldName}.`
        hasError = true
      }
    })

    // check if the card number is correct
    if (formData.number.length > 0) {
      const cardNumber = formData.number.replaceAll(" ", "")
      const correctNumber = luhnCheck(cardNumber)
      if (!correctNumber) {
        updatedFieldErrors.number = "Please enter a valid card number."
        hasError = true
      }
    }

    //checking the correct length of the expiry date
    if (formData.expMonth.length > 0) {
      if (formData.expMonth.length < 2 || formData.expYear.length < 2) {
        updatedFieldErrors.expMonth = "Enter a valid expiry date."
        hasError = true
      }
    }

    //check incorrect month
    if (formData.expMonth.length === 2) {
      const monthNum = parseInt(formData.expMonth)
      if (!monthNum || monthNum > 12 || monthNum < 1) {
        updatedFieldErrors.expMonth = "Enter a valid expiry date."
        hasError = true
      }
    }

    // checking if the expiry date hasn't past
    if (formData.expMonth.length === 2 && formData.expYear.length === 2) {
      const now = new Date()
      const currentMonth = now.getMonth() + 1
      const currentYear = now.getFullYear()
      const expiryFullYear = parseInt("20" + formData.expYear)

      if (
        currentYear > expiryFullYear ||
        (currentYear === expiryFullYear &&
          currentMonth > parseInt(formData.expMonth))
      ) {
        updatedFieldErrors.expMonth = "Enter a valid expiry date."
        hasError = true
      }
    }

    setFieldErrors(updatedFieldErrors)
    return hasError
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setFieldErrors({ ...initialFieldsObj })
    setFormError(null)
    let hasError = validateForm()
    if(selectedAddressId === "" ) {
      hasError = true
      setFormError("Select a billing address.")
    }

    if (hasError) {
      return
    }
    submitCard()
  }

  const submitCard = async () => {
    setSubmitting(true)
    try {
      const { number, expMonth, expYear, cvc } = formData
      const cardNumber = number.replaceAll(" ", "")
      const data = {
        number: cardNumber,
        expMonth,
        expYear: "20" + expYear,
        cvc,
      }

      const selectedAddress = addresses.filter(
        (address) => address.id === selectedAddressId
      )[0]
      const billingAddress = {
        firstName: selectedAddress.firstName,
        lastName: selectedAddress.lastName,
        address1: selectedAddress.address1,
        address2: selectedAddress.address2,
        country: selectedAddress.country,
        zip: selectedAddress.zip,
        city: selectedAddress.city,
        state: selectedAddress.state,
        phone: selectedAddress.phone,
      }

      const newCard = await createCard(data)
      const newCardWithAddress =  await updateCardBillingAddress(newCard.id, billingAddress)

      if (newCardWithAddress.validationErrors !== undefined) {
        setFormError(newCardWithAddress.validationErrors[0].error)
        setSubmitting(false)
      } else {
        // Protoype make default payment card.
        if (makeDefaultCard) {
          await updateUserData({
            billing: {
              account_card_id: newCardWithAddress.id,
            },
          })
        }
        const updatedCards = [...cards, newCardWithAddress]
        setCards(updatedCards)
        setUserPaymentMethods(updatedCards)
        setSubmitting(false)
        setFieldErrors({ ...initialFieldsObj })
        setFormError(null)
        closePopup()
      }
    } catch (error) {
      console.error(error)
      setFormError("An error occurred, please try again.")
      setSubmitting(false)
    }
  }

  let addressOptions = []
  if (addresses.length > 0) {
    addressOptions = addresses.map((address) => {
      const { name, address1, address2, city, zip, state, country, id } =
        address

      const labelState = state ? `${state},` : ""
      const labelZip = zip ? `${zip},` : ""
      const labelAddress2 = address2 ? `${address2},` : ""

      const label = `${name}, ${address1}, ${labelAddress2} ${labelState} ${city}, ${labelZip} ${country}`
      return { value: id, label: label }
    })
  }

  const closeAddressForm = () => {
    setOpenAddressForm(false)
    selectedAddressId("")
  }

  return (
    <FormPopupOverlay ref={overlayRef} open={isOpen} onClick={closeOnOverlay}>
      <FormPopup>
        <PopupFormContainer>
          <PopupHeaderRow>
            <PopupTitle>Add new card</PopupTitle>
            <PopupCloseBtn onClick={closePopup}>
              <IconWrapper>
                <CloseIcon />
              </IconWrapper>
            </PopupCloseBtn>
          </PopupHeaderRow>
          {formError && <Error>{formError}</Error>}
          <form onSubmit={handleSubmit}>
            <FormContent>
              <AccountInputField
                data={formData}
                name="number"
                label="Card Number"
                placeholder="Card Number"
                handleChange={handleChange}
                error={fieldErrors.number}
                reset={reset}
                setReset={setReset}
                cardNumber
              />
              <DoubleFields>
                <FieldWrapper>
                  <ExpirationInput
                    data={formData}
                    monthName="expMonth"
                    yearName="expYear"
                    label="CARD EXPIRY"
                    handleChange={handleChange}
                    error={fieldErrors.expMonth}
                    reset={reset}
                    setReset={setReset}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <AccountInputField
                    data={formData}
                    name="cvc"
                    label="CVC"
                    placeholder="CVC"
                    handleChange={handleChange}
                    error={fieldErrors.cvc}
                    reset={reset}
                    setReset={setReset}
                    type="number"
                    limit={3}
                  />
                </FieldWrapper>
              </DoubleFields>
              <AccountCheckbox
                name="default"
                checked={makeDefaultCard}
                handleChange={handleCheckboxChange}
                label="Use as default card."
              />
              <FormBlock>
                <FormSubTitle>Billing Address</FormSubTitle>
                {addresses.length > 0 ? (
                  <SelectWrapper>
                    <Select
                      onChange={(e) => setSelectedAddressId(e.value)}
                      options={addressOptions}
                      isSearchable={false}
                      styles={styledOptionStyles()}
                      placeholder="Select an existing address"
                      value={addressOptions.filter(function(option){
                        return option.value === selectedAddressId
                      })}
                    />
                  </SelectWrapper>
                ) : (
                  <Info>You have no existing addresses. Create one below.</Info>
                )}
                <NewAddressBtn onClick={() => setOpenAddressForm(true)}>
                  + Add a new address
                </NewAddressBtn>
              </FormBlock>
            </FormContent>
            <ButtonWrapper>
              <OnClickButton blue type="submit">
                {submitting ? "Creating" : "Create Card"}
              </OnClickButton>
            </ButtonWrapper>
          </form>
          <AddressForm
            isOpen={openAddressForm}
            close={closeAddressForm}
            setAddress={setSelectedAddressId}
            addresses={addresses}
            setAddresses={setAddresses}
            isBilling
          />
        </PopupFormContainer>
      </FormPopup>
    </FormPopupOverlay>
  )
}

export default CardForm
