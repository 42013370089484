import React, { useEffect } from "react"
import Seo from "../../components/Core/Seo"
import Layout from "../../components/Layout/Layout"
import colors from "../../styles/colors"
import Payment from "../../components/AccountContent/Payment"
import AccountNavigation from "../../components/AccountContent/AccountNavigation"

const PaymentPage = ({ location }) => {
  const path = location.pathname

  return (
    <Layout dark bg={colors.accountGrey}>
      <Seo
        title="Payment Page"
        description="In Good Company Payment Page"
        fullTitle="Payment Page"
        path={path}
      />
      <AccountNavigation path={path}>
        <Payment />
      </AccountNavigation>
    </Layout>
  )
}

export default PaymentPage
