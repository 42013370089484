import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import fonts from "../../styles/fonts"

const Container = styled.div`
  margin-bottom: 1.5rem;
`
const InputBlock = styled.div`
  margin-bottom: 0.5rem;
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.79rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 0.025em;
  color: #8c8984;
`
const Input = styled.input`
  ${fonts.openSansRegular};
  color: #1c1a17;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #d9d5d0;
  background-color: #fffefc;
  padding: 0.75rem 1rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  &:focus {
    outline: none;
  }
`

const Error = styled.span`
  color: #e61c1c;
  font-size: 0.889rem;
  line-height: 1.5;
  ${fonts.openSansRegular};
`

const InputContent = styled.div`
  display: flex;
  position: relative;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input:focus + span,
  input:active + span {
    opacity: 1;
    outline: 2px solid #3e3e3e;
    outline-offset: 0;
  }
`
const InputFirst = styled(Input)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  padding-right: 0;
  max-width: 46px;
  text-align: right;
`
const InputSecond = styled(Input)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  padding-left: 0;
`
const Slash = styled.div`
  ${fonts.openSansRegular};
  color: #1c1a17;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fffefc;
  padding: 0.75rem 5px;
  border-top: 1px solid #d9d5d0;
  border-bottom: 1px solid #d9d5d0;
`
const Focus = styled.span`
  display: block;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  border-radius: 0.25rem;
`

const ExpirationInput = ({
  data,
  handleChange,
  error,
  reset,
  setReset,
  monthName,
  yearName,
  label,
}) => {
  const [monthValue, setMonthValue] = useState(data[monthName])
  const [yearValue, setYearValue] = useState(data[yearName])

  useEffect(() => {
    handleChange(monthName, monthValue)
  }, [monthValue])

  useEffect(() => {
    handleChange(yearName, yearValue)
  }, [yearValue])

  useEffect(() => {
    if (reset === true) {
      setMonthValue(data[monthName])
      setYearValue(data[yearName])
      setReset(false)
    }
  }, [reset])

  let inputRef = useRef(null)

  const changeYear = (e) => {
    if (e.target.value.length < 3) {
      setYearValue(e.target.value)
    }
  }
  const changeMonth = (e) => {
    if (e.target.value.length < 3) {
      setMonthValue(e.target.value)
    }
    if (e.target.value.length >= 2) {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  return (
    <Container>
      <InputBlock>
        {label && <Label>{label}</Label>}
        <InputContent>
          <InputFirst
            type="number"
            name={monthName}
            onChange={changeMonth}
            placeholder="MM"
            value={monthValue}
            autoComplete="off"
          />
          <Focus></Focus>
          <Slash>{" / "}</Slash>
          <InputSecond
            type="number"
            name={yearName}
            onChange={changeYear}
            placeholder="YY"
            value={yearValue}
            ref={inputRef}
            autoComplete="off"
          />
          <Focus></Focus>
        </InputContent>
      </InputBlock>
      {error && <Error>{error}</Error>}
    </Container>
  )
}

ExpirationInput.propTypes = {
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool,
  setReset: PropTypes.func,
  monthName: PropTypes.string.isRequired,
  yearName: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
}

export default ExpirationInput
